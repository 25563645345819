/* istanbul ignore file */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck

/**
 * Check if a value is defined
 * @ignore
 * @param {*} value
 * @return {boolean}
 */
// eslint-disable-next-line wix-custom-rules/wixstores-file-names,import/no-default-export
export default function isDefined(value) {
  return value !== undefined && value !== null;
}
