import {CustomCssVarsFnParams} from '../../../../baseCustomCssVars';
import {SliderArrowsPlacement} from '../../../../constants';

/* istanbul ignore file */
export const arrowsNavigationWrapperCssVars = ({styleParams: styles}: CustomCssVarsFnParams) => {
  const arrowsOnImage = styles.numbers.gallery_sliderArrowsPlacement === SliderArrowsPlacement.Inside;
  const distanceBetweenArrowAndSlider = styles.numbers.gallery_sliderNavigationArrowsSpacing;
  const productItemBorderWidth = Number(styles.strings.gallery_borderWidth?.value) || 0;

  const productHasVisibleOutline = () => {
    const hasVisibleBorder = productItemBorderWidth !== 0;
    if (hasVisibleBorder) {
      return true;
    }

    const rgbaProductBackgroundAlphaValue = styles.colors.gallery_productBackground?.match(/[.?\d]+/g)?.[3] ?? '0';
    return rgbaProductBackgroundAlphaValue !== '0';
  };

  return {
    arrowsBaseOpacity: styles.booleans.gallery_sliderShowArrowsOnHoverOnly ? 0 : 1,
    arrowsOffset: arrowsOnImage ? `${productItemBorderWidth + distanceBetweenArrowAndSlider}px` : 0,
    arrowContainerPosition: arrowsOnImage ? 'absolute' : 'revert',
    arrowContainerAlignSelf: productHasVisibleOutline() ? 'center' : 'revert',
    arrowsReferenceHeight: productHasVisibleOutline() ? 'auto' : 'unset',
  };
};
