import {IProduct, ProductsManifest} from '../../../types/galleryTypes';
import {AddToCartState} from '@wix/wixstores-client-storefront-sdk/dist/es/src/services/AddToCartService/constants';
import {hasAutomaticDiscount, hasAnyDiscount} from '@wix/wixstores-client-core/dist/es/src/productOptions/productUtils';
import {IProductPreOrderAvailability} from '@wix/wixstores-client-core/dist/es/src/types/product';

export const canAddProductToCart = (product: IProduct, productsManifest: ProductsManifest): boolean => {
  return product.isInStock || isPreOrderState(product, productsManifest);
};

export const isAddToCartEnabled = (product: IProduct, productsManifest: ProductsManifest): boolean => {
  const currentState = productsManifest[product.id].addToCartState;
  const isEnabledState = currentState === AddToCartState.ENABLED;
  return isEnabledState || isPreOrderState(product, productsManifest);
};

const isPreOrderState = (product: IProduct, productsManifest: ProductsManifest): boolean => {
  const {addToCartState} = productsManifest[product.id];
  return addToCartState === AddToCartState.PRE_ORDER;
};

export const isProductItemHasDiscount = (
  product: IProduct,
  shouldUseCommonDiscountPricingMethods: boolean
): boolean => {
  if (shouldUseCommonDiscountPricingMethods) {
    return hasAnyDiscount(product);
  }
  return product.discount?.value > 0 || hasAutomaticDiscount(product);
};

const isRenderZeroPriceAllowedLegacy = (
  product: IProduct,
  allowFreeProducts: boolean,
  shouldUseCommonDiscountPricingMethods: boolean
): boolean => {
  return (
    !allowFreeProducts &&
    product.comparePrice === 0 &&
    !hasAutomaticDiscount(product) &&
    isProductItemHasDiscount(product, shouldUseCommonDiscountPricingMethods)
  );
};

export const shouldRenderPrice = (
  product: IProduct,
  allowFreeProducts: boolean,
  shouldUseCommonDiscountPricingMethods: boolean
): boolean => {
  return (
    (product.price !== 0 || allowFreeProducts) &&
    !isRenderZeroPriceAllowedLegacy(product, allowFreeProducts, shouldUseCommonDiscountPricingMethods)
  );
};

export const shouldRenderOutOfStock = (product: IProduct): boolean => {
  const isOutOfStock = !product.isInStock;
  const isPreOrderItem = product.isManageProductItems
    ? product.productItemsPreOrderAvailability !== IProductPreOrderAvailability.NO_VARIANTS
    : product.inventory.availableForPreOrder;

  return isOutOfStock && !isPreOrderItem;
};
