/* istanbul ignore file */
/* eslint-disable */
// @ts-nocheck

/**
 * Default CSS class names
 * @ignore
 * @type {InputRangeClassNames}
 */
// eslint-disable-next-line wix-custom-rules/wixstores-file-names
const DEFAULT_CLASS_NAMES = {
  activeTrack: 'input-range__track input-range__track--active',
  disabledInputRange: 'input-range input-range--disabled',
  inputRange: 'input-range',
  labelContainer: 'input-range__label-container',
  maxLabel: 'input-range__label input-range__label--max',
  minLabel: 'input-range__label input-range__label--min',
  slider: 'input-range__slider',
  sliderContainer: 'input-range__slider-container',
  track: 'input-range__track input-range__track--background',
  valueLabel: 'input-range__label input-range__label--value',
};

// eslint-disable-next-line import/no-default-export
export default DEFAULT_CLASS_NAMES;
