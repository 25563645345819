import {FilterModel, FilterType, IFilterModel} from '../../../types/galleryTypes';
import {PriceFilterModel} from '../../../models/PriceFilterModel';

export interface IActiveFilterOption {
  filterId: number;
  filterType: FilterType;
  value?: string;
  minValue?: string;
  maxValue?: string;
  removeFunc: Function;
}

export const hasActiveOptions = (filterModal: IFilterModel, includeCollectionType = false) => {
  if (filterModal.filterType === FilterType.COLLECTION && !includeCollectionType) {
    return;
  }

  switch (filterModal.filterType) {
    case FilterType.COLLECTION:
    case FilterType.COLOR_OPTION:
    case FilterType.CUSTOM_COLLECTION:
    case FilterType.LIST_OPTION:
      return !!filterModal.activeOptions.length;
    case FilterType.PRICE:
      return (
        (filterModal.activeOptions.minPrice &&
          filterModal.activeOptions.minPrice !== (filterModal as PriceFilterModel).minPrice) ||
        (filterModal.activeOptions.maxPrice &&
          filterModal.activeOptions.maxPrice !== (filterModal as PriceFilterModel).maxPrice)
      );
  }
};

export const getActiveFilterOptions = (fm: FilterModel[], filterProducts: Function): IActiveFilterOption[] => {
  const activeFilterModels = fm.filter((filterModel: FilterModel) => hasActiveOptions(filterModel, true));
  return activeFilterModels.reduce((acc, curr) => {
    const activeFilterModel = {
      filterId: curr.filterId,
      filterType: curr.filterType,
    };

    switch (curr.filterType) {
      case FilterType.COLLECTION:
      case FilterType.COLOR_OPTION:
      case FilterType.CUSTOM_COLLECTION:
      case FilterType.LIST_OPTION:
        acc.push(
          ...(curr.activeOptions as any[]).map((activeOptionId) => {
            const activeOption = curr.options.find((option) => option.key === activeOptionId);
            return {
              ...activeFilterModel,
              value: activeOption.value,
              removeFunc: () => filterProducts(curr.filterId, activeOption.key),
            };
          })
        );
        break;
      case FilterType.PRICE:
        acc.push({
          ...activeFilterModel,
          minValue: curr.options.find((option) => option.key === curr.activeOptions.minPrice).value,
          maxValue: curr.options.find((option) => option.key === curr.activeOptions.maxPrice).value,
          removeFunc: () => filterProducts(curr.filterId, {min: curr.minPrice, max: curr.maxPrice}),
        });
        break;
    }
    return acc;
  }, []);
};
