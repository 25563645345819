import React from 'react';
import s from '../ProductItem.scss';
import classNames from 'classnames';
import type {IProduct} from '../../../../types/galleryTypes';
import {withGlobals} from '../../../../globalPropsContext';
import {withTranslations} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {IGalleryGlobalProps} from '../../../../gallery/galleryGlobalStrategy';
import {
  classes as productDiscountRuleNameStylable,
  st as productDiscountRuleNameStyles,
} from './ProductDiscountRuleName.st.css';
import {Text} from 'wix-ui-tpa/cssVars';
import {shouldRenderOutOfStock, shouldRenderPrice} from '../productItemUtils';

export enum DiscountRuleNameDataHook {
  Root = 'product-discount-rule-name',
}

export interface IDiscountRuleNameProps extends IGalleryGlobalProps {
  product: IProduct;
}

class DiscountRuleName extends React.Component<IDiscountRuleNameProps> {
  public render() {
    const {
      product,
      globals: {
        shouldShowMobile,
        allowFreeProducts,
        experiments: {shouldUseCommonDiscountPricingMethods},
      },
    } = this.props;
    const classes = classNames(
      productDiscountRuleNameStyles(productDiscountRuleNameStylable.discountNameRoot, {
        useMobileFont: shouldShowMobile,
      }),
      s.productDiscountRuleName
    );
    const discountRuleName = product.itemDiscount?.discountRuleName;
    const shouldRenderPriceLayout = shouldRenderPrice(
      product,
      allowFreeProducts,
      shouldUseCommonDiscountPricingMethods
    );
    const shouldRenderOutOfStockLayout = shouldRenderOutOfStock(product);
    const shouldRenderDiscountRuleName = discountRuleName && (shouldRenderOutOfStockLayout || shouldRenderPriceLayout);

    if (shouldRenderDiscountRuleName) {
      return (
        <Text className={classes} data-hook={DiscountRuleNameDataHook.Root}>
          {discountRuleName}
        </Text>
      );
    }
    return null;
  }
}

export const ProductDiscountRuleName = withGlobals(withTranslations()(DiscountRuleName));
