import {useGlobals} from '../../../../../globalPropsContext';
import {StatesButtonStates} from 'wix-ui-tpa/cssVars';
import {AddToCartActionStatus} from '../../../../../constants';
import {AddToCartState} from '@wix/wixstores-client-storefront-sdk/dist/es/src/services/AddToCartService/constants';

export const useAddToCartState = (productId: string) => {
  const {productsManifest, addedToCartStatus, shouldShowAddToCartSuccessAnimation} = useGlobals();
  const isAddedSuccessfully = addedToCartStatus[productId] === AddToCartActionStatus.SUCCESSFUL;
  const isInProgress = addedToCartStatus[productId] === AddToCartActionStatus.IN_PROGRESS;

  const isAddToCartEnabled = () => {
    const {addToCartState} = productsManifest[productId];
    const isEnabledState = addToCartState === AddToCartState.ENABLED;
    const isPreOrderState = addToCartState === AddToCartState.PRE_ORDER;
    return isEnabledState || isPreOrderState;
  };

  const getAddToCartButtonState = (): StatesButtonStates => {
    if (shouldShowAddToCartSuccessAnimation && isAddedSuccessfully) {
      return StatesButtonStates.SUCCESS;
    } else if (isInProgress) {
      return StatesButtonStates.IN_PROGRESS;
    }
    return StatesButtonStates.IDLE;
  };

  return {isAddToCartEnabled, getAddToCartButtonState};
};
