/* eslint-disable */
/* tslint:disable */
import * as React from 'react';
export interface ChevronRightProps extends React.SVGAttributes<SVGElement> {
size?: string | number;
}
export const ChevronRight: React.FC<ChevronRightProps> = ({size, ...props}) => (
  <svg viewBox="3 3 10 10" fill="currentColor" width={ size || "20" } height={ size || "20" } {...props}>
    <path d="M6.70703 4L10.707 8L6.70703 12L6.00003 11.293L9.29303 8L6.00003 4.707L6.70703 4Z" fill="currentColor" fillRule="evenodd" clipRule="evenodd" />
  </svg>
);
ChevronRight.displayName = 'ChevronRight';
/* tslint:enable */
/* eslint-enable */
