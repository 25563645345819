/* istanbul ignore file */
import React, {useEffect, useRef, useState} from 'react';
import cx from 'classnames';
import s from './FadeIn.scss';

export const FadeIn: React.FunctionComponent = (props) => {
  const [isVisible, setIsVisible] = useState(true);
  const [itemWasFadedIn, setItemWasFadedIn] = useState(false);
  const [intersectionObserver, setIntersectionObserver] = useState(null);
  const domRef = useRef(null);
  const domRefCurrent = domRef.current;

  useEffect(() => {
    if (!intersectionObserver) {
      setIntersectionObserver(
        new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                setIsVisible(true);
                setItemWasFadedIn(true);
              } else if (!entry.isIntersecting && !itemWasFadedIn) {
                setIsVisible(false);
              }
            });
          },
          {
            threshold: 0.1,
          }
        )
      );
    }

    if (domRefCurrent && intersectionObserver && !itemWasFadedIn) {
      intersectionObserver.observe(domRefCurrent);
      return () => intersectionObserver.unobserve(domRefCurrent);
    }
  }, [isVisible, itemWasFadedIn, intersectionObserver, domRefCurrent]);

  const classNames = cx(s.fadeInSection, {
    [s.isVisible]: isVisible,
  });

  return (
    <div className={classNames} ref={domRef}>
      {props.children}
    </div>
  );
};
