import {AddToCartButtonIcon} from '../../../../../types/galleryTypes';
import {Plus, ShoppingBag, Bag, BagFill, EcomBag, Cart, CartFill, EcomCart} from '@wix/wix-ui-icons-common/on-stage';

export const useAddToCartIcon = () => {
  const iconsMapper = {
    [AddToCartButtonIcon.Bag]: Bag,
    [AddToCartButtonIcon.BagFill]: BagFill,
    [AddToCartButtonIcon.Plus]: Plus,
    [AddToCartButtonIcon.ShoppingBag]: ShoppingBag,
    [AddToCartButtonIcon.EcomBag]: EcomBag,
    [AddToCartButtonIcon.EcomCart]: EcomCart,
    [AddToCartButtonIcon.Cart]: Cart,
    [AddToCartButtonIcon.CartFill]: CartFill,
  };

  return {iconsMapper};
};
