/* istanbul ignore file */
/* eslint-disable */
// @ts-nocheck

/**
 * Captialize a string
 * @ignore
 * @param {string} string
 * @return {string}
 */
// eslint-disable-next-line import/no-default-export
export default function captialize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
