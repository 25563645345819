import React from 'react';
import s from './EmptyGallery.scss';
import classNames from 'classnames';
import {IProvidedTranslationProps} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {withGlobals} from '../../../globalPropsContext';
import {IGalleryGlobalProps} from '../../../gallery/galleryGlobalStrategy';

export interface EmptyGalleryProps extends IGalleryGlobalProps, IProvidedTranslationProps {
  hasFilters: boolean;
  localeMap?: ILocaleMap;
  isEditorCategoryPageMode?: boolean;
}

export interface ILocaleMap {
  emptyCategoryEditorTitle?: string;
  emptyCategoryEditorSubTitle?: string;
  noProductsMessageText?: string;
  noProductsFilteredMessageText?: string;
  emptyCategoryPageEditorTitle?: string;
  emptyCategoryPageEditorSubtitle?: string;
}

class EmptyGalleryComp extends React.Component<EmptyGalleryProps> {
  public render() {
    const {hasFilters, isEditorCategoryPageMode} = this.props;
    const {isCategoryVisible, isEditorMode} = this.props.globals;
    const showEmptyStateSubtitle = isEditorMode && (!isCategoryVisible || isEditorCategoryPageMode);

    const emptyStateTitleText = () => {
      if (isEditorCategoryPageMode) {
        return this.props.localeMap.emptyCategoryPageEditorTitle;
      }

      if (!isCategoryVisible) {
        return isEditorMode
          ? this.props.localeMap.emptyCategoryEditorTitle
          : this.props.localeMap.noProductsMessageText;
      } else {
        return hasFilters
          ? this.props.localeMap.noProductsFilteredMessageText
          : this.props.localeMap.noProductsMessageText;
      }
    };

    const emptyStateSubTitleText = () => {
      if (isEditorCategoryPageMode) {
        return this.props.localeMap.emptyCategoryPageEditorSubtitle;
      }
      return this.props.localeMap.emptyCategoryEditorSubTitle;
    };

    const emptyStateSubTitle = () => {
      return (
        <h2 data-hook="empty-gallery-sub-title" className={s.extraTitleSubViewer}>
          {emptyStateSubTitleText()}
        </h2>
      );
    };

    return (
      <header data-hook="empty-gallery" className={classNames(s.emptyGallery, {[s.filterEmptyState]: hasFilters})}>
        <h2 data-hook="empty-gallery-title" className={s.titleSub}>
          {emptyStateTitleText()}
        </h2>
        {showEmptyStateSubtitle && emptyStateSubTitle()}
      </header>
    );
  }
}

export const EmptyGallery = withGlobals(EmptyGalleryComp);
