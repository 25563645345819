import React from 'react';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {withGlobals} from '../../../../globalPropsContext';
import s from './EmptyCategory.scss';
import classNames from 'classnames';
import {TextButton, TextButtonPriority} from 'wix-ui-tpa';
import {IGalleryGlobalProps} from '../../../galleryGlobalStrategy';

export enum EmptyCategoryDataHook {
  Root = 'EmptyCategoryDataHook.Root',
  Title = 'EmptyCategoryDataHook.Title',
  Body = 'EmptyCategoryDataHook.Body',
  Button = 'EmptyCategoryDataHook.Button',
}

type EmptyCategoryProps = IGalleryGlobalProps & IProvidedTranslationProps;

export const EmptyCategory: React.FunctionComponent<EmptyCategoryProps> = withGlobals(
  withTranslations()(({t, globals: {hasSelectedFilters, clearFilters}}: EmptyCategoryProps) => {
    const emptyStateText = hasSelectedFilters
      ? {title: 'noFilterResultsTitle', body: 'noFilterResultsBody'}
      : {title: 'emptyCategoryTitle', body: 'emptyCategoryBody'};

    return (
      <header
        data-hook={EmptyCategoryDataHook.Root}
        className={classNames(s.emptyCategory, {[s.filterEmptyState]: hasSelectedFilters})}>
        <h2 data-hook={EmptyCategoryDataHook.Title} className={s.titleSub}>
          {t(emptyStateText.title)}
        </h2>
        <p data-hook={EmptyCategoryDataHook.Body} className={s.bodySub}>
          {t(emptyStateText.body)}
        </p>
        {hasSelectedFilters && (
          <TextButton
            data-hook={EmptyCategoryDataHook.Button}
            className={classNames(s.bodySub, s.link)}
            priority={TextButtonPriority.link}
            onClick={() => clearFilters()}>
            {t('noFilterResultsButton')}
          </TextButton>
        )}
      </header>
    );
  })
);
