import {CustomCssVarsFnParams} from '../../../baseCustomCssVars';
import {ImageCarouselArrowSize} from '../../../types/galleryTypes';

/* istanbul ignore file */
export const carouselCustomCssVars = ({styleParams: styles}: CustomCssVarsFnParams) => {
  const getSizes = () => {
    switch (styles.strings.gallery_imageCarouselArrowSize.value as ImageCarouselArrowSize) {
      case ImageCarouselArrowSize.LARGE:
        return {buttonSize: '32px', chevronSize: '20px'};
      case ImageCarouselArrowSize.MEDIUM:
        return {buttonSize: '24px', chevronSize: '14px'};
      case ImageCarouselArrowSize.SMALL:
      default:
        return {buttonSize: '16px', chevronSize: '8px'};
    }
  };

  return {
    imageCarouselButtonSize: getSizes().buttonSize,
    imageCarouselChevronSize: getSizes().chevronSize,
  };
};
