import React from 'react';
import classNames from 'classnames';
import {withGlobals} from '../../../../../globalPropsContext';
import {IGalleryGlobalProps} from '../../../../../gallery/galleryGlobalStrategy';
import {Button, StatesButtonStates, ButtonProps, Spinner, SpinnerTypes} from 'wix-ui-tpa/cssVars';
import {Check} from '@wix/wix-ui-icons-common';
import s from './IconStatesButton.scss';
import {classes as iconStatesButtonStyle} from './IconStatesButton.st.css';
import {useAddToCartIcon} from '../hooks/useAddToCartIcon';
import {useAddToCartNotificationEnd} from '../hooks/useAddToCartNotificationEnd';

const IconStatesButtonComp = (
  props: {addToCartButtonProps: ButtonProps; productId: string; state: StatesButtonStates} & IGalleryGlobalProps
) => {
  const {
    addToCartButtonProps,
    productId,
    state,
    globals: {styles, stylesParams},
  } = props;

  const icon = styles.get(stylesParams.gallery_addToCartButtonIcon);

  const {handleNotificationEnd} = useAddToCartNotificationEnd(productId);
  const {iconsMapper} = useAddToCartIcon();

  const getIconCompForIconOnlyButton = () => {
    if (state === StatesButtonStates.IN_PROGRESS) {
      return (
        <Spinner
          type={SpinnerTypes.slim}
          className={classNames(iconStatesButtonStyle.spinner, s.iconContainer, s.spinner)}
        />
      );
    }

    if (state === StatesButtonStates.SUCCESS) {
      return (
        <i className={classNames(s.iconContainer, s.success)}>
          <Check className={s.icon} />
        </i>
      );
    }

    const Icon = iconsMapper[icon];
    return (
      <i className={s.iconContainer}>
        <Icon className={s.icon} />
      </i>
    );
  };

  const onClick = (event: any) => {
    addToCartButtonProps.onClick(event);

    setTimeout(handleNotificationEnd, 3000);
  };

  return (
    <Button {...addToCartButtonProps} onClick={onClick}>
      {getIconCompForIconOnlyButton()}
    </Button>
  );
};

export const IconStatesButton = withGlobals(IconStatesButtonComp);
