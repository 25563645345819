import React, {ReactNode, FC} from 'react';
import {Experiments, GallerySlotIds} from '../../../constants';
import s from './Hero.scss';
import {useExperiments} from '@wix/yoshi-flow-editor';
import {SlotsPlaceholder} from '@wix/widget-plugins-ooi';
import {withGlobals} from '../../../globalPropsContext';
import {IGlobals} from '../../../gallery/galleryGlobalStrategy';
import classNames from 'classnames';

interface HeroSlotsWrapperProps {
  children: ReactNode;
  globals?: IGlobals;
}

export const HeroSlotsWrapper: FC<HeroSlotsWrapperProps> = withGlobals(({children, globals}: HeroSlotsWrapperProps) => {
  const {experiments} = useExperiments();
  const isPluginInstalledInHeroTopSlot = !!globals?.slots?.[GallerySlotIds.CategoryPageHeroTop];
  const isPluginInstalledInHeroBottomSlot = !!globals?.[GallerySlotIds.CategoryPageHeroBottom];

  return (
    <>
      {experiments.enabled(Experiments.RenderSlotsInGallery) && (
        <div className={classNames({[s.heroTopSlot]: isPluginInstalledInHeroTopSlot})}>
          <SlotsPlaceholder slotId={GallerySlotIds.CategoryPageHeroTop} />
        </div>
      )}
      {children}
      {experiments.enabled(Experiments.RenderSlotsInGallery) && (
        <div className={classNames({[s.heroBottomSlot]: isPluginInstalledInHeroBottomSlot})}>
          <SlotsPlaceholder slotId={GallerySlotIds.CategoryPageHeroBottom} />
        </div>
      )}
    </>
  );
});
