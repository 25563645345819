import {IGlobals} from '../../galleryGlobalStrategy';
import type {FilterModel} from '../../../types/galleryTypes';

export const shouldShowGalleryAppInLayout = (isLoaded, {showShowLightEmptyState}: IGlobals) => {
  return isLoaded && !showShowLightEmptyState;
};

export const hasRelevantFilters = (filterModels: FilterModel[]) => {
  return filterModels.length > 0;
};

export const shouldRenderSortInLayout = ({shouldShowSort}: IGlobals) => {
  return shouldShowSort;
};
