/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable react/button-has-type */
/* eslint-disable react/no-deprecated */
import * as React from 'react';
import {withGlobals} from '../../../../globalPropsContext';
import {IGalleryGlobalProps} from '../../../../gallery/galleryGlobalStrategy';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import s from './MobileFilters.scss';
import {classes} from './MobileFilters.st.css';
import autobind from 'auto-bind-es5';
import {ButtonSize, TextButton} from 'wix-ui-tpa';
import {Button} from 'wix-ui-tpa/cssVars';
import {FilterAndSortButtonStyle} from '../../../../constants';
import classNames from 'classnames';
import loadable from '@wix/yoshi-flow-editor/loadable';

interface MobileFiltersProps extends IGalleryGlobalProps, IProvidedTranslationProps {
  combineFiltersAndSort?: boolean;
}
interface MobileFiltersState {
  renderKey: number;
}

const MobileFiltersModal = loadable(
  () => import(/* webpackChunkName: "MobileFiltersModal" */ './MobileFiltersModal/MobileFiltersModal'),
  {
    resolveComponent: (component) => component.MobileFiltersModal,
  }
);

class MobileFiltersComp extends React.Component<MobileFiltersProps, MobileFiltersState> {
  private windowScrollY: number;
  private originalBodyTop: string;
  private readonly filtersBtnRef: React.RefObject<HTMLButtonElement>;
  private readonly modalContainerRef: React.RefObject<HTMLDivElement>;
  private readonly modalHeaderId = 'wixstores-filters-modal-header';
  public state = {
    renderKey: 1,
  };

  constructor(props) {
    super(props);
    autobind(this);
    this.filtersBtnRef = React.createRef<HTMLButtonElement>();
    this.modalContainerRef = React.createRef<HTMLDivElement>();
  }

  private getSelectedFiltersCount() {
    return `(${this.props.globals.numberOfSelectedFilterTypes})`;
  }

  private openMobileFiltersModal() {
    this.saveBodyTopPosition();

    this.props.globals.toggleFiltersModalVisibility(true);
    !this.props.globals.isPreviewMode && document.body.classList.add('filters-modal-open');
    document.body.style.top = `-${this.windowScrollY}px`;
    this.modalContainerRef.current?.focus();
  }

  private closeMobileFiltersModal() {
    document.body.classList.remove('filters-modal-open');
    this.props.globals.toggleFiltersModalVisibility(false);

    this.restoreBodyTopPosition();
    this.filtersBtnRef.current?.focus();
  }

  private saveBodyTopPosition() {
    this.originalBodyTop = document.body.style.top;
    this.windowScrollY = window.scrollY;
  }

  private restoreBodyTopPosition() {
    document.body.style.top = this.originalBodyTop;
    window.scrollTo(window.scrollX, this.windowScrollY);
  }

  private onModalClose(nextProps: IGalleryGlobalProps) {
    return (
      this.props.globals.shouldShowMobileFiltersModal === true &&
      nextProps.globals.shouldShowMobileFiltersModal === false
    );
  }

  public componentWillReceiveProps(nextProps: IGalleryGlobalProps) {
    if (this.onModalClose(nextProps)) {
      setTimeout(
        /* istanbul ignore next */ () => {
          this.setState(({renderKey}) => ({renderKey: renderKey + 1}));
        },
        500
      );
    }
  }

  private renderCombinedFiltersAndSort() {
    const {numberOfSelectedFilterTypes, styles, stylesParams} = this.props.globals;
    const {t} = this.props;
    const filterAndSortButtonStyle = styles.get(stylesParams.gallery_categoryMobileFilterAndSortButtonStyle);
    return filterAndSortButtonStyle === FilterAndSortButtonStyle.TEXT ? (
      <TextButton
        onClick={() => this.openMobileFiltersModal()}
        data-hook="mobile-filters-text-button-style-text"
        className={s.textButtonStyle}>
        <span className={s.combinedFiltersAndSortButton}>{t('mobileFiltersAndSortingText')}</span>
        {numberOfSelectedFilterTypes > 0 && (
          /* istanbul ignore next */
          <span className={s.categoryFiltersCount}>{` ${this.getSelectedFiltersCount()}`}</span>
        )}
      </TextButton>
    ) : (
      <Button
        onClick={() => this.openMobileFiltersModal()}
        className={classNames(classes.mobileFiltersButton, s.boxButtonStyle)}
        size={ButtonSize.tiny}
        data-hook="mobile-filters-text-button-style-box">
        <span
          className={classNames(s.combinedFiltersAndSortButton, {
            [s.boxStyleButtonSpan]: numberOfSelectedFilterTypes > 0,
          })}>
          {t('mobileFiltersAndSortingText')}
        </span>
        {numberOfSelectedFilterTypes > 0 && (
          /* istanbul ignore next */
          <span className={s.categoryFiltersCount}> {`${this.getSelectedFiltersCount()}`}</span>
        )}
      </Button>
    );
  }

  public render() {
    const {clearFilters, numberOfSelectedFilterTypes, shouldShowClearFilters, shouldShowMobileFiltersModal} =
      this.props.globals;
    const {combineFiltersAndSort} = this.props;
    return (
      <>
        {combineFiltersAndSort ? (
          this.renderCombinedFiltersAndSort()
        ) : (
          <button
            onClick={() => this.openMobileFiltersModal()}
            ref={this.filtersBtnRef}
            className={s.mobileFiltersButton}
            data-hook="mobile-filters-button">
            {this.props.t('mobileFiltersButtonText')}
            {numberOfSelectedFilterTypes > 0 && (
              <span className={s.filtersCount}> {this.getSelectedFiltersCount()}</span>
            )}
          </button>
        )}
        <MobileFiltersModal
          className="filters-modal"
          closeModal={() => this.closeMobileFiltersModal()}
          clearFilters={clearFilters}
          shouldShowClearFilters={shouldShowClearFilters}
          isOpen={shouldShowMobileFiltersModal}
          key={this.state.renderKey}
          modalRef={this.modalContainerRef}
          filtersBtnRef={this.filtersBtnRef}
          modalHeaderId={this.modalHeaderId}
          combineFiltersAndSort={combineFiltersAndSort}
        />
      </>
    );
  }
}

export const MobileFilters = withGlobals(withTranslations()(MobileFiltersComp));
