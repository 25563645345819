/* istanbul ignore file: todo tests @titk */

/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable react/button-has-type */
/* eslint-disable @typescript-eslint/unbound-method */
import React, {CSSProperties, memo} from 'react';
import _ from 'lodash';
import a11y from '@wix/wixstores-client-core/dist/es/src/assets/styles/_accessibility.scss';
import {classes as quantityStyle, style as styleQuantity} from './QuantityCounter.st.css';
import autobind from 'auto-bind-es5';
import classNames from 'classnames';
import s from './ProductItem.scss';
import {classes as productItemStylable, st as productItemStyles} from './ProductItem.st.css';
import {Counter, Text} from 'wix-ui-tpa/cssVars';
import {IGalleryGlobalProps} from '../../../gallery/galleryGlobalStrategy';
import {ButtonContentType, IProduct, ProductItemsState, RibbonPlacementId} from '../../../types/galleryTypes';
import {ProductPrice} from './ProductPrice/ProductPrice';
import {QuantityCalculator} from '@wix/wixstores-client-core/dist/es/src/quantity-calculator/quantityCalculator';
import {withGlobals} from '../../../globalPropsContext';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {ProductOptionsChangeData, ProductOptionsWithGlobals} from './ProductOptions/ProductOptions';
import {ProductOptionType} from '@wix/wixstores-graphql-schema';
import {ProductRibbon} from './Ribbon/Ribbon';
import {AddToCartButton as AddToCart} from './AddToCartButton/AddToCartButton';
import {SlotsPlaceholder} from '@wix/widget-plugins-ooi';
import {SlotContextProvider} from '@wix/widget-plugins-ooi-context';
import {AddToCartActionStatus, Experiments, ProductBorderStyleType, ProductItemSlotIds} from '../../../constants';
import {ConditionalRender} from '../ConditionalRender/ConditionalRender';
import {ImageLinkContainer} from './ImageLinkContainer/ImageLinkContainer';
import {canAddProductToCart, isAddToCartEnabled, shouldRenderOutOfStock} from './productItemUtils';
import {ProductDiscountRuleName} from './ProductDiscountRuleName/ProductDiscountRuleName';
import {ProductTaxDisclaimer} from './ProductTaxDisclaimer/ProductTaxDisclaimer';
import {ProductBasePrice} from './ProductBasePrice/ProductBasePrice';

export enum DataHook {
  Root = 'product-item-root',
  AddToCartButton = 'product-item-add-to-cart-button',
  QuickViewButton = 'product-item-quick-view-button',
  AddToCartLoadingIndicator = 'product-item-add-to-cart-loading-indicator',
  LineBetweenNameAndPrice = 'product-item-line-between-name-and-price',
  Name = 'product-item-name',
  Price = 'product-item-price',
  BasePriceComponent = 'base-price-component',
  NameAndPriceLayout = 'product-item-name-and-price-layout',
  ProductDetails = 'product-item-product-details',
  ProductDetailsLink = 'product-item-product-details-link',
  QuantityCounter = 'product-item-quantity-counter',
  QuantityCounterWrapper = 'product-item-quantity-counter-wrapper',
  Ribbon = 'product-item-ribbon',
  SrOnlyName = 'product-item-sr-only-name',
  Options = 'product-item-options',
  NotImageContainer = 'not-image-container',
  SlotsPlaceholderContainer = 'slots-placeholder',
  OutOfStock = 'product-item-out-of-stock',
  OutOfStockTextContainer = 'out-of-stock-text-container',
}

export interface IProductItemProps extends IGalleryGlobalProps {
  announce: (content: string) => void;
  index: number;
  product: IProduct;
  innerRef?: Function;
  disabled?: boolean;
  style?: CSSProperties;
  className?: string;
}

interface IProductItemState {
  errors: {quantity?: string};
  limits: {quantity: {min: number; max: number}};
  selections: number[];
  quantity: number;
  showHoverPreview: boolean;
  isHovered: boolean;
}

const PREVIEW_DURATION = 1000;

const Slot = memo<{resourceId: string; slotId: string}>(({resourceId, slotId}) => (
  <SlotContextProvider resourceId={resourceId}>
    <SlotsPlaceholder slotId={slotId} data-hook={`${DataHook.SlotsPlaceholderContainer}-${slotId}`} />
  </SlotContextProvider>
));

export class ProductItem extends React.Component<IProductItemProps & IProvidedTranslationProps, IProductItemState> {
  private readonly debouncedStopHoverPreview = _.debounce(
    () => this.setState({showHoverPreview: false}),
    PREVIEW_DURATION
  );

  private readonly productLink = React.createRef<HTMLAnchorElement>();
  private readonly rootRef = React.createRef<HTMLDivElement>();

  public state: IProductItemState = {
    isHovered: false,
    showHoverPreview: false,
    quantity: 1,
    selections: [],
    errors: {quantity: undefined},
    limits: {
      quantity: {
        min: 1,
        max: 1,
      },
    },
  };

  private hoverType: string;

  constructor(props) {
    super(props);
    autobind(this);

    const {styles, stylesParams} = this.props.globals;
    this.hoverType = styles.get(stylesParams.gallery_hoverType).value;
  }

  public static getDerivedStateFromProps(
    props: IProductItemProps,
    state: IProductItemState
  ): Partial<IProductItemState> {
    const {shouldRemoveModifiersSelectionIdsFromUserSelections} = props.globals.experiments;

    const userOptionSelections = state.selections.map((selectionId) => {
      return {id: selectionId};
    });
    const inventoryRange = QuantityCalculator.getQuantitiesRange(props.product, userOptionSelections, {
      isRemoveModifiersSelectionIdsFromUserSelections: shouldRemoveModifiersSelectionIdsFromUserSelections,
    });
    const inventoryCount = inventoryRange[inventoryRange.length - 1];

    return {
      limits: {
        quantity: {
          min: 1,
          max: inventoryCount,
        },
      },
    };
  }

  public focus(): void {
    this.productLink.current.focus();
  }

  private renderSrOnlyName() {
    return (
      <span data-hook={DataHook.SrOnlyName} className={a11y.srOnly}>
        {this.props.product.name}
      </span>
    );
  }

  private renderName() {
    const {
      product,
      globals: {shouldShowMobile},
    } = this.props;

    const htmlTag = this.props.globals.htmlTags.productNameHtmlTag;
    const classes = classNames(
      productItemStyles(productItemStylable.root, {
        useMobileFont: shouldShowMobile,
      }),
      s.productName
    );

    return (
      <Text tagName={htmlTag} className={classes} data-hook={DataHook.Name}>
        {product.name}
      </Text>
    );
  }

  private renderPrice() {
    const {
      globals: {
        productsManifest,
        allowFreeProducts,
        textsMap: {
          productPriceBeforeDiscountSR,
          productOutOfStockText,
          productPriceAfterDiscountSR,
          productPriceWhenThereIsNoDiscountSR,
          measurementUnits,
          pricePerUnitSR,
        },
        experiments: {shouldUseCommonDiscountPricingMethods},
      },
      product,
    } = this.props;

    /* istanbul ignore next: todo tests @titk  */
    const productWithPriceInfo = canAddProductToCart(product, productsManifest)
      ? {
          ...product,
          ...this.productVariantInfo /* istanbul ignore next: todo tests @titk */?.priceInfo,
        }
      : product;

    const textsMap = {
      productPriceBeforeDiscountSR,
      productOutOfStockText,
      productPriceAfterDiscountSR,
      productPriceWhenThereIsNoDiscountSR,
      measurementUnits,
      pricePerUnitSR,
    };

    return (
      <ProductPrice
        product={productWithPriceInfo}
        allowFreeProducts={allowFreeProducts}
        textsMap={textsMap}
        shouldUseCommonDiscountPricingMethods={shouldUseCommonDiscountPricingMethods}
        fromPrice={this.productPriceRange}
      />
    );
  }

  private renderOutOfStock() {
    const {
      product,
      globals: {
        textsMap: {productOutOfStockText},
        useExperiments,
      },
    } = this.props;
    const shouldEnableOutOfStockAlignment = useExperiments.enabled(Experiments.EnableOutOfStockAlignment);
    const shouldRenderOutOfStockLayout = shouldRenderOutOfStock(product);

    if (shouldRenderOutOfStockLayout) {
      return (
        <ConditionalRender
          by={'notShowAddToCartButtonOrIconOnlyButton'}
          dataHook={DataHook.OutOfStockTextContainer}
          className={classNames({[s.outOfStockTextContainer]: shouldEnableOutOfStockAlignment})}>
          <span data-hook={DataHook.OutOfStock} className={s.outOfStock}>
            {productOutOfStockText}
          </span>
        </ConditionalRender>
      );
    }
    return null;
  }

  private static renderLineBetweenNameAndPrice() {
    return (
      <div className={s.productDividerWrapper}>
        <hr data-hook={DataHook.LineBetweenNameAndPrice} className={s.productDivider} aria-hidden="true" />
      </div>
    );
  }

  private renderProductInfoRibbon() {
    const {
      product: {ribbon},
      globals: {shouldShowMobile},
    } = this.props;

    return (
      <ProductRibbon shouldShowMobile={shouldShowMobile} ribbon={ribbon} placement={RibbonPlacementId.ProductInfo} />
    );
  }

  private handleQuantityCounterChange(val: string) {
    const {t} = this.props;
    const {min, max} = this.state.limits.quantity;
    const quantity = parseInt(val, 10) || 0;

    const minimumError = quantity < min && t('quantityMinimumAmountSR', {minimum: min});
    const maximumError = quantity > max && t('quantityMaximumAmountSR', {inventory: max});

    this.setState(
      ({errors}) => {
        const nextErrors = {...errors, quantity: minimumError || maximumError};
        return {quantity, errors: nextErrors};
      },
      () => {
        if (this.state.errors.quantity) {
          this.props.announce(this.state.errors.quantity);
        } else {
          this.props.announce(
            this.props.t('quantityTotalSR', {
              quantity,
            })
          );
        }
      }
    );
  }

  private readonly handleSelectionsChange = (selections: number[], data: ProductOptionsChangeData) => {
    const {shouldResetQuantityUponSelectionChange} = this.props.globals.experiments;
    const newState: any = shouldResetQuantityUponSelectionChange ? {selections, quantity: 1} : {selections};

    this.setState(newState, () => {
      const {
        props: {
          globals: {handleProductsOptionsChange},
          product: {id: productId},
        },
      } = this;

      const {optionType} = data;

      void handleProductsOptionsChange({
        productId,
        selectionIds: selections,
        optionType,
      });
    });
  };

  private handleProductItemClick(event: React.MouseEvent<HTMLAnchorElement>) {
    const {
      globals: {handleProductItemClick, reportProductItemClick},
      product: {id: productId},
      index,
      disabled,
    } = this.props;

    const shouldOpenInNewTab = event.ctrlKey || event.metaKey;
    if (shouldOpenInNewTab && !disabled) {
      reportProductItemClick({
        productId,
        index,
      });
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    if (disabled) {
      return;
    }

    handleProductItemClick({
      biData: {
        productId,
        index,
      },
    });
  }

  public componentDidMount() {
    const {isSSR} = this.props.globals;

    if (isSSR) {
      return;
    }

    this.rootRef.current.style.setProperty('--hrefCursor', 'inherit');
    this.rootRef.current.style.setProperty('--hrefPointerEvents', 'inherit');
  }

  public componentDidUpdate(prevProps: Readonly<IProductItemProps>): void {
    const {
      product,
      globals: {addedToCartStatus: prevAddedToCartStatus},
    } = prevProps;
    const {
      globals: {addedToCartStatus, shouldShowAddToCartSuccessAnimation, stylesParams},
      announce,
      t,
    } = this.props;

    const previousHoverType = this.hoverType;
    const currentHoverType = this.props.globals.styles.get(stylesParams.gallery_hoverType).value;
    this.hoverType = currentHoverType;

    if (previousHoverType !== currentHoverType) {
      this.setState({showHoverPreview: true});

      this.debouncedStopHoverPreview();
    }

    const prevAddToCartStatus = prevAddedToCartStatus[product.id];
    const addToCartStatus = addedToCartStatus[product.id];

    if (
      shouldShowAddToCartSuccessAnimation &&
      prevAddToCartStatus !== AddToCartActionStatus.SUCCESSFUL &&
      addToCartStatus === AddToCartActionStatus.SUCCESSFUL
    ) {
      announce(t('addToCartSuccessSR', {productName: product.name}));
    }
  }

  private get productVariantInfo() {
    const {
      globals: {productsVariantInfoMap},
      product,
    } = this.props;

    return productsVariantInfoMap?.[product.id];
  }

  private get productPriceRange(): string | undefined {
    const {
      globals: {productsPriceRangeMap},
      product,
    } = this.props;

    return productsPriceRangeMap?.[product.id];
  }

  private readonly getRevealClassNames = () => {
    if (this.props.globals.isOptionsRevealEnabled) {
      const hasOptions = this.props.product.options.length > 0;
      const hasSingleOption =
        this.props.product.options.length === 1 ||
        this.props.product.options.every(({optionType}) => optionType === ProductOptionType.COLOR);
      const hasSelections = this.productVariantInfo.variantSelectionIds.length > 0;
      const shouldBeHidden = hasOptions && hasSingleOption && !hasSelections;
      return {
        [s.hiddenModeHidden]: shouldBeHidden,
        [s.hiddenModeVisible]: !shouldBeHidden,
      };
    }
    return {};
  };

  private readonly renderQuantityCounter = () => {
    const {
      globals: {shouldShowMobile, textsMap, useExperiments},
    } = this.props;
    const galleryProductOptionsAndQuantityRoundCornersInViewer = useExperiments.enabled(
      Experiments.GalleryProductOptionsAndQuantityRoundCornersInViewer
    );

    const {
      quantity,
      limits: {
        quantity: {min, max},
      },
    } = this.state;

    const errors = {
      error: !!this.state.errors.quantity,
      errorMessage: this.state.errors.quantity,
    };
    const shouldUseNewMargin = this.props.globals.useExperiments.enabled(
      Experiments.EnableQualityOptionsStylingChanges
    );

    const getQuantityClass = (isNewMargin: boolean): string => (isNewMargin ? s.quantity : s.oldQuantity);
    return (
      <div
        className={classNames(getQuantityClass(shouldUseNewMargin), this.getRevealClassNames())}
        data-hook={DataHook.QuantityCounterWrapper}>
        <Counter
          {...errors}
          decrementAriaLabel={textsMap.quantityRemoveSR}
          incrementAriaLabel={textsMap.quantityAddSR}
          aria-label={textsMap.quantityChooseAmountSR}
          inputAriaLabel={textsMap.quantityInputSR}
          onChange={this.handleQuantityCounterChange}
          data-hook={DataHook.QuantityCounter}
          value={quantity}
          min={min}
          max={max}
          className={classNames(
            /* istanbul ignore next */
            styleQuantity(quantityStyle.quantityCounter, {
              customized: galleryProductOptionsAndQuantityRoundCornersInViewer,
              customizedOld: !galleryProductOptionsAndQuantityRoundCornersInViewer,
            }),
            {
              [s.showOnHover]: !shouldShowMobile,
            }
          )}
        />
      </div>
    );
  };

  public renderOptions = () => {
    const {product} = this.props;

    return (
      <div data-hook={DataHook.Options}>
        <ProductOptionsWithGlobals
          variantsAvailability={this.productVariantInfo.selectionsAvailability}
          onSelectionIdsChange={this.handleSelectionsChange}
          product={product}
          selectionIds={this.productVariantInfo.variantSelectionIds}
          isItemHovered={this.state.isHovered}
        />
      </div>
    );
  };

  public onAddToCartClicked = () => {
    this.setState({quantity: 1});
  };

  private readonly renderAddToCartButton = () => {
    const {product, index} = this.props;
    const {quantity} = this.state;
    const {
      shouldShowMobile,
      addedToCartStatus,
      handleAddToCart,
      textsMap: {
        galleryAddToCartButtonText,
        addToCartContactSeller,
        addToCartOutOfStock,
        galleryAddToCartPreOrderButtonText,
      },
      styles,
      stylesParams,
      shouldShowAddToCartSuccessAnimation,
      useExperiments,
    } = this.props.globals;

    const shouldShowOnHover = styles.get(stylesParams.gallery_addToCartButtonShowOnHover);

    const addToCartProps = {
      product,
      index,
      quantity,
      onAddToCartClicked: this.onAddToCartClicked,
      showOnHoverClassName: s.showOnHover,
      addedToCartStatus,
      handleAddToCart,
      galleryAddToCartButtonText,
      addToCartContactSeller,
      addToCartOutOfStock,
      galleryAddToCartPreOrderButtonText,
      shouldShowAddToCartSuccessAnimation,
      shouldShowMobile,
      shouldShowOnHover,
    };

    return useExperiments.enabled(Experiments.AllowAddToCartButtonContentTypesInViewer) ? (
      <>
        <ConditionalRender by={'showAddToCartTextButton'} className={s.addToCartButtonConditionalContainer}>
          <AddToCart {...addToCartProps} contentType={ButtonContentType.Text} />
        </ConditionalRender>
        <ConditionalRender by={'showAddToCartIconAndTextButton'} className={s.addToCartButtonConditionalContainer}>
          <AddToCart {...addToCartProps} contentType={ButtonContentType.IconAndText} />
        </ConditionalRender>
        <ConditionalRender by={'showAddToCartIconButton'} className={s.addToCartButtonConditionalContainer}>
          <AddToCart {...addToCartProps} contentType={ButtonContentType.Icon} />
        </ConditionalRender>
        <Slot resourceId={product.id} slotId={ProductItemSlotIds.GalleryItemAdditionalButtons} />
      </>
    ) : (
      <AddToCart {...addToCartProps} contentType={ButtonContentType.Text} />
    );
  };

  private handleQuickViewButtonClick(event: React.MouseEvent<HTMLButtonElement>) {
    const {
      globals: {openQuickView},
      product: {id: productId},
      index,
    } = this.props;
    const {quantity} = this.state;
    const selectionIds = this.productVariantInfo?.variantSelectionIds || [];

    event.preventDefault();
    event.stopPropagation();

    openQuickView({productId, index, selectionIds, quantity});
  }

  private renderQuickViewButton() {
    const {
      textsMap: {quickViewButtonText},
    } = this.props.globals;

    return (
      <button
        type="button"
        className={s.quickViewButton}
        data-hook={DataHook.QuickViewButton}
        onClick={this.handleQuickViewButtonClick}>
        {quickViewButtonText}
      </button>
    );
  }

  private get isProductItemsLoading(): boolean {
    const {
      globals: {experiments, productsManifest},
    } = this.props;
    return (
      experiments.shouldUseGalleryProductItemsLazyLoadingForV3 &&
      productsManifest[this.props.product.id].productItemsState === ProductItemsState.LOADING
    );
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  public render() {
    const {index, globals, product, style, t} = this.props;
    const {
      shouldShowMobile,
      shouldShowProductOptions,
      isLiveSiteMode,
      isPreviewMode,
      productsManifest,
      styles,
      stylesParams,
      experiments,
      useExperiments,
    } = globals;
    const {isAllowGalleryProductRoundCornersInViewer} = experiments;

    const shouldOmitFlexGrow = useExperiments.enabled(Experiments.EnableQualityOptionsStylingChanges);
    const allowLinkToProductPageInSSR = useExperiments.enabled(Experiments.GalleryAllowLinkToProductPageInSSR);
    const isAddToCartButtonOnImageAllowedInViewer = useExperiments.enabled(
      Experiments.AllowAddToCartButtonOnImageInViewer
    );

    if (!productsManifest[product.id]) {
      return null;
    }

    const {showHoverPreview} = this.state;
    const options = this.props.product.options;
    const noOptions = options.length === 0;
    const singleOption = options.length === 1;
    const multipleColorOptions = !noOptions && options.every(({optionType}) => optionType === ProductOptionType.COLOR);
    const productPageUrl = productsManifest[product.id].url;
    const ariaLabelBase = product.name;
    const ariaLabel = product.ribbon ? `${ariaLabelBase}. ${product.ribbon}` : ariaLabelBase;

    const borderStyle = styles.get(stylesParams.gallery_productBorderStyleType);

    const getDetailsContainerClass = (): string =>
      shouldOmitFlexGrow ? s.productDetailsContainer : s.oldProductDetailsContainer;

    const isNamePriceLayoutEnabled = useExperiments.enabled(Experiments.ShouldShowNamePriceSideBySideInViewer);

    return (
      <div
        data-slug={product.urlPart}
        role="group"
        aria-label={t('carouselContainerLabel', {productName: ariaLabel})}
        onMouseEnter={() => !shouldShowMobile && this.setState({isHovered: true})}
        onMouseLeave={() => !shouldShowMobile && this.setState({isHovered: false})}
        style={style}
        ref={this.rootRef}
        data-hook={DataHook.Root}
        className={classNames(
          this.props.className,
          s.productItem,
          borderStyle === ProductBorderStyleType.INLINE ? s.inlineBorder : s.outline,
          {
            [s.hoverPreview]: showHoverPreview,
            [s.roundCorners]: isAllowGalleryProductRoundCornersInViewer,
            [s.oddProductItem]: index % 2,
            [s.disableHrefInSsr]: !allowLinkToProductPageInSSR,
          }
        )}>
        <ImageLinkContainer
          index={index}
          product={product}
          showHoverPreview={showHoverPreview}
          renderAddToCartButton={this.renderAddToCartButton}
          renderQuickViewButton={this.renderQuickViewButton}
          handleProductItemClick={this.handleProductItemClick}
          quantity={this.state.quantity}
        />
        <div data-hook={DataHook.NotImageContainer} className={s.notImageContainer}>
          <div className={getDetailsContainerClass()}>
            <a
              href={isLiveSiteMode || isPreviewMode ? productPageUrl : null}
              onClick={this.handleProductItemClick}
              className={classNames({[s.productDetailsLink]: !shouldOmitFlexGrow})}
              data-hook={DataHook.ProductDetailsLink}
              ref={this.productLink}>
              <ConditionalRender by={'notShowProductName'}>{this.renderSrOnlyName()}</ConditionalRender>
              <ConditionalRender by={'showProductDetails'}>
                <div className={s.productDetails} data-hook={DataHook.ProductDetails}>
                  {product.ribbon && (
                    <ConditionalRender by={'shouldShowRibbonOnProductInfo'} className={s.ribbonContainer}>
                      {this.renderProductInfoRibbon()}
                    </ConditionalRender>
                  )}
                  <div
                    className={classNames({
                      [s.namePriceLayout]: isNamePriceLayoutEnabled,
                      [s.oldNamePriceLayout]: !isNamePriceLayoutEnabled,
                    })}
                    data-hook={DataHook.NameAndPriceLayout}>
                    <ConditionalRender by={'gallery_showProductName'}> {this.renderName()}</ConditionalRender>
                    <ConditionalRender by={'showDivider'}>
                      {ProductItem.renderLineBetweenNameAndPrice()}
                    </ConditionalRender>
                    <ConditionalRender by={'gallery_showPrice'} className={s.priceContainer}>
                      {this.renderPrice()}
                    </ConditionalRender>
                  </div>

                  <ConditionalRender by={'gallery_showPrice'} className={s.priceContainer}>
                    {this.renderOutOfStock()}
                    <ProductBasePrice product={product} />
                    <ConditionalRender by={'gallery_showDiscountName'} className={s.discountName}>
                      <ProductDiscountRuleName product={product} />
                    </ConditionalRender>
                    <ProductTaxDisclaimer product={product} />
                  </ConditionalRender>
                </div>
              </ConditionalRender>
            </a>
            <div className={s.slotContainer}>
              <Slot resourceId={product.id} slotId={ProductItemSlotIds.ProductGalleryDetailsSlot1} />
            </div>
            {canAddProductToCart(product, productsManifest) && shouldShowProductOptions && (
              <ConditionalRender
                by={shouldShowMobile ? 'mobile:gallery_showProductOptions' : 'gallery_showProductOptionsButton'}
                className={s.productInputs}>
                {this.renderOptions()}
              </ConditionalRender>
            )}
            {this.isProductItemsLoading && <div style={{fontSize: '16px'}}>Loading...</div>}
            {isAddToCartEnabled(product, productsManifest) && (
              <div>
                <ConditionalRender
                  by={shouldShowMobile ? 'mobile:gallery_showAddToCartButton' : 'gallery_showAddToCartButton'}>
                  <ConditionalRender
                    by={shouldShowMobile ? 'mobile:gallery_showQuantity' : 'gallery_showAddToCartQuantity'}>
                    {noOptions && this.renderQuantityCounter()}
                    {(singleOption || multipleColorOptions) && (
                      <ConditionalRender
                        by={
                          shouldShowMobile ? 'mobile:gallery_showProductOptions' : 'gallery_showProductOptionsButton'
                        }>
                        {this.renderQuantityCounter()}
                      </ConditionalRender>
                    )}
                  </ConditionalRender>
                </ConditionalRender>
              </div>
            )}
          </div>
          {isAddToCartButtonOnImageAllowedInViewer ? (
            <ConditionalRender by={'showAddToCartInProductInfo'} className={s.productFooter}>
              {this.renderAddToCartButton()}
            </ConditionalRender>
          ) : (
            <ConditionalRender
              by={shouldShowMobile ? 'mobile:gallery_showAddToCartButton' : 'gallery_showAddToCartButton'}
              className={s.productFooter}>
              {this.renderAddToCartButton()}
            </ConditionalRender>
          )}
        </div>
      </div>
    );
  }
}

export const ProductItemWithGlobals: React.FC<IProductItemProps & IProvidedTranslationProps> = withGlobals(
  withTranslations()(ProductItem)
);
