import * as React from 'react';
import {CarouselMobile} from './CarouselMobile/CarouselMobile';
import {CarouselDesktop} from './CarouselDesktop/CarouselDesktop';

export interface CarouselAriaLabels {
  arrowLeft: string;
  arrowRight: string;
}

interface CarouselProps {
  isMobile: boolean;
  shouldShowArrowsOnHover: boolean;
  ariaLabels: CarouselAriaLabels;
}

export const TRANSITION_TIME = 500;

export class Carousel extends React.Component<CarouselProps> {
  public render() {
    const {isMobile, shouldShowArrowsOnHover, ariaLabels, children} = this.props;
    return isMobile ? (
      <CarouselMobile>{children}</CarouselMobile>
    ) : (
      <CarouselDesktop ariaLabels={ariaLabels} shouldShowArrowsOnHover={shouldShowArrowsOnHover}>
        {children}
      </CarouselDesktop>
    );
  }
}
