import loadable from '@wix/yoshi-flow-editor/loadable';
import type {FloatingDropdownProps, DropdownProps, DialogProps} from 'wix-ui-tpa/cssVars';

export const LazyFloatingDropDown = loadable<FloatingDropdownProps>(
  // eslint-disable-next-line sonarjs/no-duplicate-string
  () => import(/* webpackChunkName: "LazyWUTComponents" */ './WUTComponents'),
  {
    resolveComponent: (component) => component.FloatingDropdown,
  }
);

export const LazyDropDown = loadable<DropdownProps>(
  () => import(/* webpackChunkName: "LazyWUTComponents" */ './WUTComponents'),
  {
    resolveComponent: (component) => component.Dropdown,
  }
);

export const LazyDialog = loadable<DialogProps>(
  () => import(/* webpackChunkName: "LazyWUTComponents" */ './WUTComponents'),
  {
    resolveComponent: (component) => component.Dialog,
  }
);
