/* istanbul ignore file */
/* eslint-disable */
// @ts-nocheck
// eslint-disable-next-line wix-custom-rules/wixstores-file-names
import {isNumber} from '../utils';

/**
 * @ignore
 * @param {Object} props - React component props
 * @return {?Error} Return Error if validation fails
 */
// eslint-disable-next-line import/no-default-export
export default function rangePropType(props) {
  const {maxValue, minValue} = props;

  if (!isNumber(minValue) || !isNumber(maxValue)) {
    return new Error('"minValue" and "maxValue" must be a number');
  }

  if (minValue >= maxValue) {
    return new Error('"minValue" must be smaller than "maxValue"');
  }
}
