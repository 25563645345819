import {galleryGlobalsStrategy} from '../../../gallery/galleryGlobalStrategy';
import {getWrappedComponent} from '../../../appConfig';
import stylesParams from '../stylesParams';
import {GalleryApp} from '../../../gallery/components/GalleryApp/GalleryApp';

export default getWrappedComponent({
  Component: GalleryApp,
  stylesParams,
  globalPropsStrategy: galleryGlobalsStrategy,
  sliderGallery: false,
});
