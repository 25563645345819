import React from 'react';
import {classes as stylableClasses} from './CategoryListHeader.st.css';
import {Text, TextButton, TextButtonPriority} from 'wix-ui-tpa/cssVars';
import {ConditionalRender} from '../../../../common/components/ConditionalRender/ConditionalRender';
import {useCategories} from '../../../hooks/useCategories';
import {ChevronLeft, ChevronRight} from '@wix/wix-ui-icons-common/on-stage';
import {useEnvironment} from '@wix/yoshi-flow-editor';

export const CategoryListHeaderDataHook = {
  ChevronLeft: 'CategoryListHeaderDataHook.ChevronLeft',
  ChevronRight: 'CategoryListHeaderDataHook.ChevronRight',
};

export const CategoryListHeader = ({dataHook}: {dataHook?: string}) => {
  const {canHaveBackButton, backButtonUrl, currentCategory, listHeaderCategory, navigateToCategory} = useCategories();
  const {isRTL} = useEnvironment();

  const backButtonIcon = isRTL ? (
    <ChevronRight data-hook={CategoryListHeaderDataHook.ChevronRight} />
  ) : (
    <ChevronLeft data-hook={CategoryListHeaderDataHook.ChevronLeft} />
  );

  const renderHeader = (enableLinkBackToParent: boolean) => {
    if (enableLinkBackToParent) {
      return (
        <TextButton
          className={stylableClasses.textButtonRoot}
          data-hook={dataHook}
          priority={TextButtonPriority.secondary}
          prefixIcon={backButtonIcon}
          as={'a'}
          href={backButtonUrl}
          onClick={() => navigateToCategory(currentCategory.parentCategoryId)}>
          {listHeaderCategory.name}
        </TextButton>
      );
    }

    return (
      <Text className={stylableClasses.textRoot} data-hook={dataHook}>
        {listHeaderCategory.name}
      </Text>
    );
  };

  return (
    <>
      <ConditionalRender by={'categoryListHeaderGoesBackToParent'}>{renderHeader(canHaveBackButton)}</ConditionalRender>
      <ConditionalRender by={'categoryListHeaderDoesNothing'}>{renderHeader(false)}</ConditionalRender>
    </>
  );
};
