import React from 'react';
import s from './Ribbon.scss';
import {RibbonPlacementId} from '../../../../types/galleryTypes';
import classNames from 'classnames';
import {withTranslations} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';

export enum RibbonDataHook {
  RibbonOnImage = 'RibbonDataHook.RibbonOnImage',
  RibbonOnProductInfo = 'RibbonDataHook.RibbonOnProductInfo',
}

export interface IRibbonProps {
  ribbon: string;
  placement: RibbonPlacementId;
  shouldShowMobile: boolean;
}

class RibbonComp extends React.Component<IRibbonProps> {
  public render() {
    const {ribbon, placement, shouldShowMobile} = this.props;
    const dataHook =
      placement === RibbonPlacementId.OnImage ? RibbonDataHook.RibbonOnImage : RibbonDataHook.RibbonOnProductInfo;
    return (
      <div
        className={classNames(
          s.ribbon,
          {[s.onImage]: placement === RibbonPlacementId.OnImage},
          {[s.productInfo]: placement === RibbonPlacementId.ProductInfo},
          {[s.mobile]: shouldShowMobile}
        )}
        data-hook={dataHook}>
        {ribbon}
      </div>
    );
  }
}

export const ProductRibbon = withTranslations()(RibbonComp);
