import {AddToCartActionStatus} from '../../../../../constants';
import {useGlobals} from '../../../../../globalPropsContext';

export const useAddToCartNotificationEnd = (productId: string) => {
  const {updateAddToCartStatus} = useGlobals();

  const handleNotificationEnd = () => {
    updateAddToCartStatus(productId, AddToCartActionStatus.IDLE);
  };

  return {handleNotificationEnd};
};
