/* istanbul ignore file */
/* eslint-disable */
// @ts-nocheck

/**
 * Calculate the absolute difference between two numbers
 * @ignore
 * @param {number} numA
 * @param {number} numB
 * @return {number}
 */
// eslint-disable-next-line import/no-default-export
export default function length(numA, numB) {
  return Math.abs(numA - numB);
}
