import {MobileSort} from './MobileSort/MobileSort';
import React from 'react';
import {DataHook} from '../../ExtendedGridGallery/ExtendedGridGallery';
import {MobileFilters} from './MobileFilters/MobileFilters';
import {IGlobals} from '../../../gallery/galleryGlobalStrategy';
import s from './MobileFiltersAndSort.scss';
import {hasRelevantFilters} from '../../../gallery/components/GalleryApp/appUtils';

export const MobileFiltersAndSort = ({filterModels, shouldShowSort}: IGlobals) => {
  const shouldShowFilters = hasRelevantFilters(filterModels);

  const getFiltersView = () => {
    return (
      <div className={s.mobileFiltersAndSort} data-hook={DataHook.MobileContainer}>
        {shouldShowFilters && <MobileFilters />}
        {shouldShowSort && <MobileSort areFiltersEnabled={shouldShowFilters} />}
      </div>
    );
  };

  return getFiltersView();
};
