/* istanbul ignore file */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React, {Component, ReactElement} from 'react';
import classNames from 'classnames';
import s from './RevealAnimation.scss';

export enum DataHook {
  OptionContainer = 'reveal-animation-option-container',
}

export interface IRevealAnimationProps {
  isVisible: boolean;
  preserveSpace?: boolean;
}

interface IRevealAnimationState {
  height: number;
}

export class RevealAnimation extends Component<IRevealAnimationProps, IRevealAnimationState> {
  private readonly ref;

  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.state = {
      height: 0,
    };
  }

  public componentDidUpdate(props) {
    if (this.props.isVisible !== props.isVisible) {
      this.setState({height: this.getHeight()});
    }
  }

  private getHeight() {
    return this.ref?.current.getBoundingClientRect().height;
  }

  private getStyles() {
    if (this.props.preserveSpace) {
      return {};
    }
    return {
      maxHeight: this.props.isVisible ? `${this.state.height}px` : 0,
    };
  }

  private getClassNames() {
    return classNames({
      [s.visible]: this.props.isVisible,
      [s.hidden]: !this.props.isVisible,
      [s.collapsed]: !this.props.preserveSpace,
    });
  }

  public render() {
    const child = React.Children.only(this.props.children) as ReactElement;
    return (
      <div className={this.getClassNames()} style={this.getStyles()} data-hook={DataHook.OptionContainer}>
        {React.cloneElement(child, {ref: this.ref})}
      </div>
    );
  }
}
