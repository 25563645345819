/* eslint-disable */
/* tslint:disable */
import * as React from 'react';
export interface XProps extends React.SVGAttributes<SVGElement> {
size?: string | number;
}
export const X: React.FC<XProps> = ({size, ...props}) => (
  <svg viewBox="0 0 24 24" fill="currentColor" width={ size || "24" } height={ size || "24" } {...props}>
    <defs>
      <path id="a" d="M12 11.077L17.077 6l.923.923L12.923 12 18 17.077l-.923.923L12 12.923 6.923 18 6 17.077 11.077 12 6 6.923 6.923 6 12 11.077z" />
    </defs>
    <use fillRule="evenodd" xlinkHref="#a" />
  </svg>
);
X.displayName = 'X';
/* tslint:enable */
/* eslint-enable */
