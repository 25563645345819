/* eslint-disable */
/* tslint:disable */
import * as React from 'react';
export interface ArrowDownProps extends React.SVGAttributes<SVGElement> {
size?: string | number;
}
export const ArrowDown: React.FC<ArrowDownProps> = ({size, ...props}) => (
  <svg viewBox="0 0 12 7" fill="currentColor" width={ size || "12" } height={ size || "7" } {...props}>
    <defs>
      <path fill="currentColor" d="M 11.1 0.3 Q 10.8509765625 0.291796875 10.65 0.45 L 6.05 4.95 1.4 0.45 Q 1.1990234375 0.291796875 0.9 0.3 0.659375 0.30546875 0.45 0.5 0.29140625 0.70078125 0.3 0.95 0.3056640625 1.2400390625 0.5 1.4 L 5.6 6.3 Q 5.78984375 6.503125 6.05 6.5 6.31015625 6.503125 6.5 6.3 L 11.55 1.4 Q 11.744140625 1.2400390625 11.75 0.95 11.75859375 0.70078125 11.55 0.5 11.390625 0.30546875 11.1 0.3 Z"
        id="arrowDownIcon" />
    </defs>
    <use xlinkHref="#arrowDownIcon" />
  </svg>
);
ArrowDown.displayName = 'ArrowDown';
/* tslint:enable */
/* eslint-enable */
