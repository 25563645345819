import React from 'react';
import classNames from 'classnames';
import s from './ImageLinkContainer.scss';
import {withGlobals} from '../../../../globalPropsContext';
import {IGalleryGlobalProps} from '../../../../gallery/galleryGlobalStrategy';
import {HoverType, IProduct, RibbonPlacementId} from '../../../../types/galleryTypes';
import {ProductMedia} from '../ProductMedia/ProductMedia';
import {ConditionalRender} from '../../ConditionalRender/ConditionalRender';
import {withTranslations} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {canAddProductToCart} from '../productItemUtils';
import {ProductRibbon} from '../Ribbon/Ribbon';
import {useExperiments} from '@wix/yoshi-flow-editor';
import {Experiments} from '../../../../constants';

export type ImageLinkContainerProps = IGalleryGlobalProps & {
  index: number;
  product: IProduct;
  showHoverPreview: boolean;
  renderAddToCartButton: () => React.JSX.Element;
  renderQuickViewButton: () => React.JSX.Element;
  handleProductItemClick: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  quantity: number;
};

export enum DataHook {
  Root = 'product-item-container',
}

export const ImageLinkContainer: React.FunctionComponent<ImageLinkContainerProps> = withGlobals(
  withTranslations()((props: ImageLinkContainerProps) => {
    const {
      product,
      product: {id: productId, ribbon},
      showHoverPreview,
      renderAddToCartButton,
      renderQuickViewButton,
      handleProductItemClick,
      globals: {
        isLiveSiteMode,
        isPreviewMode,
        productsManifest,
        productsVariantInfoMap,
        shouldShowMobile,
        imageMode,
        imageRatio,
        textsMap: {digitalProductBadgeAriaLabelText},
        styles,
        stylesParams,
      },
    } = props;

    const {experiments} = useExperiments();

    const isAddToCartButtonOnImageAllowedInViewer = experiments.enabled(
      Experiments.AllowAddToCartButtonOnImageInViewer
    );

    const productPageUrl = productsManifest[productId].url;

    const productVariantInfo = productsVariantInfoMap?.[productId];

    const currentMedia = productVariantInfo?.mediaItems ?? product.media;

    const hoverType = styles.get(stylesParams.gallery_hoverType).value;

    const renderImage = () => {
      const productWithCurrentMedia = canAddProductToCart(product, productsManifest)
        ? {
            ...product,
            media: currentMedia,
          }
        : product;

      return (
        <ProductMedia
          classNames={{thumbnail: s.productThumbnail, image: s.productImage}}
          product={productWithCurrentMedia}
          isMobile={shouldShowMobile}
          hoverType={hoverType}
          imageRatioId={imageRatio}
          imageModeId={imageMode}
          globals={props.globals}
          textsMap={{digitalProductBadgeAriaLabel: digitalProductBadgeAriaLabelText}}>
          {product.ribbon && (
            <ConditionalRender by={'shouldShowRibbonOnImage'} className={s.ribbonContainer}>
              {renderOnImageRibbon()}
            </ConditionalRender>
          )}
          {!shouldShowMobile && (
            <ConditionalRender by={'showQuickView'} className={s.quickViewButtonContainer}>
              {renderQuickViewButton()}
            </ConditionalRender>
          )}
          {isAddToCartButtonOnImageAllowedInViewer ? (
            <ConditionalRender by={'showAddToCartOnImage'} className={s.addToCartButtonContainer}>
              {renderAddToCartButton()}
            </ConditionalRender>
          ) : undefined}
        </ProductMedia>
      );
    };

    const classes = classNames(
      s.productItemLink,
      s.customizePadding,
      shouldShowMobile || (hoverType === HoverType.Alternate && currentMedia.length < 2) ? s.none : s[hoverType],
      {
        [s.hoverPreview]: showHoverPreview,
      }
    );

    const renderOnImageRibbon = () => {
      return (
        <ProductRibbon shouldShowMobile={shouldShowMobile} ribbon={ribbon} placement={RibbonPlacementId.OnImage} />
      );
    };

    return (
      <a
        href={isLiveSiteMode || isPreviewMode ? productPageUrl : null}
        tabIndex={-1}
        onClick={handleProductItemClick}
        className={classes}
        data-hook={DataHook.Root}>
        {renderImage()}
      </a>
    );
  })
);
