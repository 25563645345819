import {CustomCssVarsFnParams, ShouldRenderVarsFnParams} from '../../../baseCustomCssVars';
import {CategoryListHeaderBehavior, LongCategoryListLayout} from '../../../types/galleryTypes';

/* istanbul ignore file */
export const categoryTreeCustomCssVars = ({styleParams: styles}: CustomCssVarsFnParams) => {
  const shouldIndentCategoryList =
    styles.booleans.gallery_showCategoryListHeader &&
    styles.numbers.gallery_categoryListHeaderBehavior === CategoryListHeaderBehavior.GO_TO_PARENT;

  const shouldLimitLongCategoryList = styles.numbers.gallery_longCategoryListLayout === LongCategoryListLayout.LIMIT;

  return {
    shouldLimitLongCategoryList: shouldLimitLongCategoryList ? 1 : 0,
    categoryListDisplay: shouldLimitLongCategoryList ? '-webkit-box' : 'block',
    categoryListIndentationPx: shouldIndentCategoryList ? '28px' : '0px',
  };
};

export const categoryTreeShouldRenderVars = ({numbers}: ShouldRenderVarsFnParams) => {
  return {
    categoryListHeaderGoesBackToParent:
      numbers.gallery_categoryListHeaderBehavior === CategoryListHeaderBehavior.GO_TO_PARENT,
    categoryListHeaderDoesNothing: numbers.gallery_categoryListHeaderBehavior === CategoryListHeaderBehavior.DO_NOTHING,
    shouldLimitCategoryList: numbers.gallery_longCategoryListLayout === LongCategoryListLayout.LIMIT,
  };
};
