import React from 'react';
import {ShouldRenderVarsKeys} from '../../../baseCustomCssVars';
import {IGalleryGlobalProps} from '../../../gallery/galleryGlobalStrategy';
import {useStylesParams} from '../../../stylesParamsContext';
import {useEnvironment} from '@wix/yoshi-flow-editor';
import {useAllStyles} from '../../../allStylesContext';

type ConditionalRenderProps = {
  by: Partial<ShouldRenderVarsKeys>;
  dataHook?: string;
} & Omit<React.HTMLProps<HTMLDivElement>, 'style'> &
  IGalleryGlobalProps;

export const ConditionalRender: React.FunctionComponent<ConditionalRenderProps> = ({
  by,
  className,
  dataHook,
  children,
  ...props
}: ConditionalRenderProps) => {
  const stylesParams = useStylesParams();
  const {stylesForAllBreakpoints, shouldRenderVarsForAllBreakpoints} = useAllStyles();
  const {isEditor} = useEnvironment();

  const shouldRender = () => {
    if (by in stylesParams) {
      return stylesForAllBreakpoints.some((stylesPerBreakpoint) => stylesPerBreakpoint.booleans[by]);
    }

    return shouldRenderVarsForAllBreakpoints.some((shouldRenderVarsPerBreakpoint) => shouldRenderVarsPerBreakpoint[by]);
  };

  if (!isEditor && !shouldRender()) {
    return null;
  }

  const style: React.CSSProperties = {
    display: `var(--${by.replace(':', '-')}-display, inherit)`,
  };

  return (
    <div className={className} data-hook={dataHook} style={style} {...props}>
      {children}
    </div>
  );
};
