/* istanbul ignore file */
/* eslint-disable */
// @ts-nocheck

/**
 * Calculate the distance between pointA and pointB
 * @ignore
 * @param {Point} pointA
 * @param {Point} pointB
 * @return {number} Distance
 */
// eslint-disable-next-line import/no-default-export,wix-custom-rules/wixstores-file-names
export default function distanceTo(pointA, pointB) {
  const xDiff = (pointB.x - pointA.x) ** 2;
  const yDiff = (pointB.y - pointA.y) ** 2;

  return Math.sqrt(xDiff + yDiff);
}
