import {ShouldRenderVarsFnParams, CustomCssVarsFnParams} from '../../../baseCustomCssVars';
import {VerticalAlignments} from '../../../types/galleryTypes';
import {CategoryHeaderLayout} from '../../../constants';
import {CSSProperties} from 'react';

/* istanbul ignore file */
// eslint-disable-next-line sonarjs/cognitive-complexity
export const heroCustomCssVars = ({styleParams: styles, isMobile}: CustomCssVarsFnParams) => {
  const showCategoryHeaderImage = styles.booleans.gallery_showCategoryHeaderImage;
  const showCategoryHeaderDescription = styles.booleans.gallery_showCategoryHeaderDescription;
  const showCategoryHeaderName = styles.booleans.gallery_showCategoryHeaderName;

  return {
    showCategoryHeaderImageGap: (showCategoryHeaderImage && (isMobile ? '16px' : '24px')) || 'inherit',
    categoryHeaderTextOutsideFlexDirection: getCategoryHeaderTextOutsideFlexDirection(
      styles.numbers.gallery_categoryHeaderLayout
    ),
    categoryHeaderSpaceBetweenTitleDescription:
      showCategoryHeaderName && showCategoryHeaderDescription
        ? styles.numbers.gallery_categoryHeaderSpaceBetweenTitleDescription.toString()
        : '0',
    categoryCountAndFilterContainerJustifyContent: styles.booleans.gallery_showProductsCounter
      ? 'space-between'
      : 'flex-end',
    categoryHeaderImageMinHeight: showCategoryHeaderImage
      ? `${styles.numbers.gallery_categoryHeaderImageMinHeight}px`
      : 'unset',
    categoryDescriptionContainerColor: showCategoryHeaderImage
      ? styles.colors.gallery_categoryDescriptionContainerColor
      : 'unset',
    categoryDescriptionContainerBorderColor: showCategoryHeaderImage
      ? styles.colors.gallery_categoryDescriptionContainerBorderColor
      : 'unset',
    categoryDescriptionContainerBorderWidth: showCategoryHeaderImage
      ? `${styles.numbers.gallery_categoryDescriptionContainerBorderWidth}px`
      : 'unset',
    categoryDescriptionContainerBorderStyle: showCategoryHeaderImage ? 'solid' : 'unset',
    categoryDescriptionContainerCornerRadius: showCategoryHeaderImage
      ? `${styles.numbers.gallery_categoryDescriptionContainerCornerRadius}px`
      : 'unset',
    categoryDescriptionContainerPadding: showCategoryHeaderImage ? '24px' : 'inherit',
    categoryDescriptionContainerMargin: showCategoryHeaderImage ? '24px' : 'inherit',
    categoryInfoVerticalAlignment: getAlignItemsPropValue(styles.strings.gallery_categoryInfoVerticalAlignment),
  };
};

export const heroShouldRenderVars = ({booleans}: ShouldRenderVarsFnParams) => {
  const showCategoryHeaderImage = booleans.gallery_showCategoryHeaderImage;
  const showCategoryHeaderDescription = booleans.gallery_showCategoryHeaderDescription;
  const showCategoryHeaderName = booleans.gallery_showCategoryHeaderName;

  return {
    showCategoryHeaderNameOrDescription: showCategoryHeaderName || showCategoryHeaderDescription,
    showHeroSection:
      booleans.gallery_showCategoryHeaderSection &&
      (showCategoryHeaderImage || showCategoryHeaderName || showCategoryHeaderDescription),
    showCategoriesBreadcrumbs: booleans.gallery_showCategoriesBreadcrumbs,
  };
};

const getAlignItemsPropValue = (verticalAlignment) => {
  switch (verticalAlignment.value) {
    case VerticalAlignments.TOP:
      return 'start';
    case VerticalAlignments.CENTER:
      return 'center';
    case VerticalAlignments.BOTTOM:
    default:
      return 'end';
  }
};

const getCategoryHeaderTextOutsideFlexDirection = (layout: CategoryHeaderLayout): CSSProperties['flexDirection'] => {
  switch (layout) {
    case CategoryHeaderLayout.TextAbove:
      return 'column-reverse';
    case CategoryHeaderLayout.TextBelow:
    default:
      return 'column';
  }
};
