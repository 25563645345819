import {CustomCssVarsFnParams} from '../../../baseCustomCssVars';
import {SliderArrowsPlacement} from '../../../constants';
import {GridType} from '../../../types/galleryTypes';

/* istanbul ignore file */
export const sliderGalleryCssVars = ({styleParams: styles}: CustomCssVarsFnParams) => {
  const gridType: GridType = styles.numbers.gallery_gridType;
  const arrowsOnImage = styles.numbers.gallery_sliderArrowsPlacement === SliderArrowsPlacement.Inside;
  const showTeaser = styles.booleans.gallery_sliderShowTeaser;

  const getSidePadding = () => {
    const showSliderNavigationArrows = styles.booleans.gallery_showSliderNavigationArrows;
    const distanceBetweenArrowAndSlider = styles.numbers.gallery_sliderNavigationArrowsSpacing;
    const productItemBorderWidth = Number(styles.strings.gallery_borderWidth?.value) || 0;

    const arrowBorderWidth = styles.numbers.gallery_sliderArrowsContainerBorderWidth;
    const arrowsWidth = styles.numbers.gallery_sliderArrowsSize;
    const arrowsOffset = distanceBetweenArrowAndSlider + productItemBorderWidth;
    const shouldHaveArrowsPadding = showSliderNavigationArrows && !arrowsOnImage;
    return shouldHaveArrowsPadding ? arrowBorderWidth + arrowsWidth + arrowsOffset : 0;
  };

  const shouldShowTeaserOffset = () => {
    return arrowsOnImage && showTeaser;
  };

  const getTeaserSpace = () => {
    if (!showTeaser) {
      return 0;
    }

    return gridType === GridType.AUTO ? 0.7 : 0.4;
  };

  return {
    sliderSidePadding: `${getSidePadding()}px`,
    spaceBetweenSlides:
      gridType === GridType.AUTO ? styles.numbers.gallery_gapSizeColumn : styles.numbers.galleryMarginColumn * 2,
    teaserOffset: shouldShowTeaserOffset() ? styles.numbers.gallery_sliderTeaserOffset : 0,
    teaserSpace: getTeaserSpace(),
  };
};
