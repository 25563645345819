/* eslint-disable react/display-name */
import React, {useContext, useMemo} from 'react';
import {IStyles} from '@wix/tpa-settings';
import {useStyles} from '@wix/tpa-settings/react';
import {getBaseShouldRenderValues} from './baseCustomCssVars';
import {useEnvironment} from '@wix/yoshi-flow-editor';

interface AllStylesContext {
  stylesForAllBreakpoints: IStyles[];
  shouldRenderVarsForAllBreakpoints: Record<string, boolean>[];
}

const AllStylesContext = React.createContext<AllStylesContext>({} as AllStylesContext);

export function withAllStyles(Component) {
  return (props) => {
    const styles = useStyles();
    const {isRTL, isMobile} = useEnvironment();
    const memoizedGetStylesForAllBreakpoints = useMemo(() => styles.getStylesForAllBreakpoints(), [styles]);
    const memoizedBooleansForAllBreakpoints = useMemo(
      () =>
        memoizedGetStylesForAllBreakpoints.map((stylesPerBreakpoint) =>
          /* istanbul ignore next */
          getBaseShouldRenderValues({
            booleans: stylesPerBreakpoint.booleans,
            numbers: stylesPerBreakpoint.numbers,
            isMobile,
            isRTL,
          })
        ),
      [memoizedGetStylesForAllBreakpoints, isMobile, isRTL]
    );

    return (
      <AllStylesContext.Provider
        value={{
          stylesForAllBreakpoints: memoizedGetStylesForAllBreakpoints,
          shouldRenderVarsForAllBreakpoints: memoizedBooleansForAllBreakpoints,
        }}>
        <Component {...props} />
      </AllStylesContext.Provider>
    );
  };
}

export function useAllStyles() {
  return useContext(AllStylesContext);
}
