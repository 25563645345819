/* istanbul ignore file */
/* eslint-disable */
// @ts-nocheck

/**
 * Check if a value is a number
 * @ignore
 * @param {*} value
 * @return {boolean}
 */
// eslint-disable-next-line import/no-default-export,wix-custom-rules/wixstores-file-names
export default function isNumber(value) {
  return typeof value === 'number';
}
