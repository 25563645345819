import React from 'react';
import s from './ProductBasePrice.scss';
import type {IProduct} from '../../../../types/galleryTypes';
import {withGlobals} from '../../../../globalPropsContext';
import {IGalleryGlobalProps} from '../../../../gallery/galleryGlobalStrategy';
import {BasePrice} from '@wix/wixstores-client-common-components/dist/es/src/BasePrice/BasePrice';

export const BasePriceDataHook = {
  BasePriceComponent: 'base-price-component',
};

export interface IProductBasePriceProps extends IGalleryGlobalProps {
  product: IProduct;
}

class BasePriceComponent extends React.Component<IProductBasePriceProps> {
  private readonly getBasePriceTranslationSR = (translation, vars) => {
    //eslint-disable-next-line prefer-named-capture-group
    return translation.replace(/\{\{([^}]+)\}\}/gi, (_match, k) => {
      return vars[k.trim()];
    });
  };

  public render() {
    const {
      product,
      globals: {
        textsMap: {
          productPriceBeforeDiscountSR,
          productOutOfStockText,
          productPriceAfterDiscountSR,
          productPriceWhenThereIsNoDiscountSR,
          measurementUnits,
          pricePerUnitSR,
        },
      },
    } = this.props;

    const shouldRenderBasePrice = !!product.formattedPricePerUnit;
    if (!shouldRenderBasePrice) {
      return null;
    }

    const {
      formattedPricePerUnit,
      pricePerUnitData: {baseQuantity, baseMeasurementUnit},
    } = product;

    const textsMap = {
      productPriceBeforeDiscountSR,
      productOutOfStockText,
      productPriceAfterDiscountSR,
      productPriceWhenThereIsNoDiscountSR,
      measurementUnits,
      pricePerUnitSR,
    };

    const noun = baseQuantity === 1 ? 'singular' : 'plural';
    const unitTranslation = textsMap.measurementUnits[baseMeasurementUnit].abbr;
    const screenReaderText = this.getBasePriceTranslationSR(textsMap.pricePerUnitSR, {
      basePrice: formattedPricePerUnit,
      units: `${baseQuantity} ${textsMap.measurementUnits[baseMeasurementUnit][noun]}`,
    });

    return (
      <BasePrice
        data-hook={BasePriceDataHook.BasePriceComponent}
        className={s.basePrice}
        formattedPricePerUnit={formattedPricePerUnit}
        baseQuantity={baseQuantity}
        unitTranslation={unitTranslation}
        screenReaderText={screenReaderText}
        withRTLSupport
      />
    );
  }
}

export const ProductBasePrice = withGlobals(BasePriceComponent);
